@import "./variables.scss";

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  @include transition;
}

input,
button,
label {
  cursor: pointer;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: "Noto Sans Armenian";
  font-style: normal;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: $advancedBack;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $blue;
    // background-color: #3f60a0;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: darken($blue, 3%);
    // background-color: darken(#3f60a0, 3%);
  }
}

.contain {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 50px;

  @media screen and (min-width: 1440px) {
    padding: 0 120px;
  }

  @media screen and (max-width: 912px) {
    padding: 0 32px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
}

.loader {
  width: 100%;
  height: 100vh;
  background: $bgAdmin;
  z-index: 99;
  @include flexCenter;
  margin: 0 auto;

  // img {
  //   width: 77px;
  //   height: 77px;
  // }

  img {
    @media screen and (max-width: 1022px) {
      width: 202px;
      height: 202px;
    }
  }
}

.title {
  color: $text;
  font-size: 40px;
  font-weight: 800;
  line-height: 44px;
  padding-bottom: 60px;

  @media screen and (max-width: 1122px) {
    font-size: 33px;
    padding-bottom: 48px;
  }

  @media screen and (max-width: 768px) {
    font-size: 24px;
    padding-bottom: 24px;
  }
}

img {
  object-fit: cover;
}

// for removing react-select down icon
.css-1hb7zxy-IndicatorsContainer {
  flex-direction: row-reverse !important;
}

.css-1xc3v61-indicatorContainer,
.css-15lsz6c-indicatorContainer {
  // color: transparent !important;
  display: none !important;
}

// .css-hlgwow {
//   padding: 1px 44px 1px 0px !important;
// }

@media screen and (max-width: 768px) {
  .css-1hb7zxy-IndicatorsContainer,
  .css-1u9des2-indicatorSeparator {
    display: none !important;
  }

  .css-epapr7-control {
    border-radius: 0px !important;
  }
}

// Dashboard all pages
.dashboard__layout {
  display: flex;
}

article {
  background: $bgAdmin;
  width: 100%;

  // dashboardi bolor ejeri titlenery u voj miayn
  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: $text;
  }
}

textarea {
  font-family: "Noto Sans Armenian";
  font-style: normal;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

// For home page all blocks
.block {
  padding-top: 120px;

  @media screen and (max-width: 1122px) {
    padding-top: 90px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 48px;
  }
}

// alerts
.custom-toast {
  word-wrap: break-word !important;
  min-width: fit-content !important;
}
